// Cross browser opacity
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); // IE8
}

// Verticle align only works down to IE9
@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

// REM font sizing. Doesn't have to reset HTML to 62.5% font size
@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

// Hide elements visually, but keep them available for screen-readers.
//
// Used for information required for screen-reader users to understand and use
// the site where visual display is undesirable. Information provided in this
// manner should be kept concise, to avoid unnecessary burden on the user.
// "!important" is used to prevent unintentional overrides.
@mixin element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}

// Hide elements from all users.//
// Used for elements which should not be immediately displayed to any user. An
// example would be a collapsible fieldset that will be expanded with a click
// from a user. The effect of this class can be toggled with the jQuery show()
// and hide() functions.
@mixin element-hidden {
  display: none;
}

@mixin text-invisible {
  display: block;
  text-indent: -9999em;
  overflow: hidden;
}

// Hide elements visually, but keep them available for screen-readers.
//
// Used for information required for screen-reader users to understand and use
// the site where visual display is undesirable. Information provided in this
// manner should be kept concise, to avoid unnecessary burden on the user.
// "!important" is used to prevent unintentional overrides.
@mixin element-invisible {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

@mixin horizontal-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow: hidden;

  > li {
    @extend %display-block;
    @extend %pull-left;

    &:last-child {
      margin-right: 0;
    }
  }
}

@mixin respond-to($name) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $name);

  // If the key exists in the map
  @if map-has-key($breakpoints, $name) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $name))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$name}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder { /* Firefox 18- */
    @content;
  }

  &::-moz-placeholder {  /* Firefox 19+ */
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}
