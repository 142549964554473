.recipe-search-results {
  padding: 0 30px;

  h2 {
    padding-top: 20px;
    margin: 0;
  }
}

.container__results {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: stretch;
}
