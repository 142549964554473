.comments-area {
  margin-left: 30px;
  margin-right: 30px;
  font-size: rem(18px);

  @include respond-to(medium) { margin-right: 0; }
  .children {
    list-style: none;
    margin: 44px 0 0 0;
    padding: 0;
  }
}
.comments-title { margin-bottom: 0; }
.comment-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.depth-1, .depth-2 {
  padding: 24px 0 24px 24px;
  margin-bottom: 16px;

  &:last-child { margin-bottom: 0; }
}

.depth-1 {
  @include transition(border, 0.2s);
  border: 1px solid transparent;

  &:hover { border: 1px solid rgba($brand-blue, 0.6); }

  .reply {
    position: absolute;
    top: 24px;
    right: 24px;

    a {
      @include transition(border, 0.2s);
      padding: 8px 8px 6px 8px;
      border: 1px solid rgba($brand-blue, 0.3);
      text-transform: uppercase;
      line-height: 1;

      &:hover { border-color: rgba($brand-blue, 0.8); }
      &:after {
        content: '\02193';
        padding-left: 8px;
        font-size: em(14px);
      }
    }
  }
}

.depth-2 {
  padding-right: 24px;
  background-color: rgba($brand-blue, 0.2);
  border-left: 4px solid $brand-blue;

  .reply { display: none; }
}

.comment {
  position: relative;
  background-color: rgba($brand-blue, 0.1);
}

.comment-author { line-height: 1; }

.comment-metadata {
  margin-bottom: 24px;
  font-size: em(14px);

  a { color: $gray2; }
}

.fn {
  font-size: em(21px);
  font-style: normal;
  text-transform: capitalize;
}

.comment-content {
  font-size: em(18px);

  p { margin-bottom: 0; }
}

.comment-respond { margin-top: 30px; }

.comment-form-comment {
  label { @extend %display-block; }

  textarea {
    width: 100%;
    padding: 16px;
    border-color: $gray2;
  }
}

.form-allowed-tags,
.form-allowed-tags code { font-size: em(14px); }

.form-allowed-tags code {
  display: block;
  padding: 8px 16px;
  margin-top: 8px;
  border: 1px solid $gray1;
}

.form-submit {
  input[type="submit"] { @extend .btn; }
}
