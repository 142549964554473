/**
 * $Figures
 */

/* To remove any br or p tags that the WordPress editor likes to throw in */
figure img + br,
figure a + br,
figure img + p,
figure a + p  { display: none; }

figure {
  margin: 0;
  padding: 0;
  &.alignnone {
    margin: 0 30px em(32px) 0;
  }
  &.alignright {
  @extend %pull-right;
    margin: 0 30px 10px 20px;
  }
  &.alignleft {
    @extend %pull-left;
    margin: 0 20px 10px 0;
  }
}

.wp-caption-text {
  font-size: rem(14);
  letter-spacing: 1px;
  padding: 10px;
}
img[class*='wp-image-'] {
  border: 2px solid $brand-gray;
}
