// Typography
$rokkit-font: Rokkitt;
$yanone-font: Yanone Kaffeesatz;
$base-font-family: Rokkitt, Helvetica Neue, Helvetica, sans-serif;

$base-headline-font: $yanone-font, Georgia, serif;

$base-font-size: 16px;
$base-line-height: 1.5;


// Colors
$brand-red: #E2725F;
$brand-green: #BCBC54;
$brand-blue: #6FCCD8;
$brand-gray: #5E5F61;

$black: #000;
$white: #fff;

$gray1: #E6E6E6;
$gray2: #9B9B9B;
$gray3: #656565;
// $gray4: ;
$gray5: #383939;

// -----------------------------------------------------------------------------
// Social Media
// -----------------------------------------------------------------------------
$facebook: #4c66a4 !default;
$pinterest: #cb2027 !default;
$twitter: #2fc2ef !default;

// Breakpoints
$breakpoints: (
  'mobile'  : ( max-width:  767px ),
  'small'   : ( min-width:  768px ),
  'medium'  : ( min-width:  960px ),
  'large'   : ( min-width: 1200px )
);
