.featured--recipe,
.featured--post {
  @include clearfix;
  position: relative;
  border-bottom: 1px solid $gray1;
  padding: 8px;
  padding-bottom: 30px;

  @include respond-to(medium) {
    padding-bottom: 8px;
  }
  h2 {
    @extend %color_brand-red;
    font-size: em(36px);
    margin-bottom: rem(14px);
    a {
      @extend %color_brand-red;
      @include transition(color 0.2s);
      &:hover{
        color: darken($brand-red, 10%);
      }
    }
  }
  .content {
    padding: 0 16px;

    @include respond-to(medium) {
      float: left;
      width: 48%;
      padding: 0 24px;
    }
  }

  p { margin: 0; }
  .date {
    padding: 8px 16px;
    margin-bottom: 16px;
    border-top: 1px solid $brand-blue;
    border-right: none;
    font-size: em(12px);
  }
}
.icon--latest{
  @extend %display-block;
  @extend %background_brand-red;
  @extend %color_white;
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 8px 16px;
  line-height: 1;
  font-size: rem(16px);
  letter-spacing: 1px;
}
.featured--recipe-image{
  @extend %display-block;
  margin-bottom: 16px;
  img{
    width: 100%;
    max-width: none;
  }

  @include respond-to(medium){
    float: left;
    width: 52%;
    margin-bottom: 0;
  }
}
.featured--post{
  border-top: 1px solid $gray1;
  border-bottom: 0;
  padding-bottom: 30px;
}

.about {
  @include clearfix;
  display: none;
  background-color: rgba($brand-blue, 0.05);
  border-bottom: 1px solid $gray1;
  padding: 30px;
  p {
    padding: 60px;
    margin: 0;
  }

  .btn--close{
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .avatar {
    @extend %pull-right;
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px $brand-green;
    margin: 20px 60px 0 60px;
    img{
      max-width: none;
    }
  }
}

.recent--recipe {
  @include outer-container;
  padding: 0 30px;
  margin: 30px 0;

  @include respond-to(medium){
    margin: 60px 0;
  }
}

.recent--recipe-list {
  @include respond-to(small) {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
  }
}
