ul.index-lists {

}
ul.index-lists li {
	font-size: smaller; 
}

//just stack dietary restrictions in one row
#filter-dietary ul.index-list{
	column-count: 1;
}