.img-featured {
  position: relative;
  overflow: hidden;
  border-left: 8px solid $brand-blue;
  margin-bottom: 30px;

  img {
    width: 100%;
  }
}
