img, media {
  display: block;
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

nav ul{
  list-style: none;
}
