#footer {
  @include clearfix;
  position: relative;
  border-top: 1px solid $gray1;

  .l-container {
    padding: 30px 30px 40px 30px;
    padding-bottom: 40px;
    padding-top: 30px;
  }
  .icon--logo-circles-footer{
    background-position: right bottom;
  }
  .footer__logo {
    @extend %display-block;
    @extend %pull-right;
    margin-top: 24px;
    margin-left: 80px;

    @include respond-to(mobile){
      display: block;
      text-align: center;
      float: none;
      margin: 0 auto;
      width: 90%;
      height: auto;
      margin-bottom: 20px;
    }
  }

  @include respond-to(mobile){
    .nav__main{
      margin-bottom: 20px;
      display: block;
    }
  }
}
.footer__wrapper {
  @include clearfix;
  nav {
    @extend %pull-left;
  }
}
.footer__social {
  @include horizontal-list;
  @extend %pull-right;
  li {
    margin: 0 5px;
  }
}
