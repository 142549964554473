/**
 * $PAGE - SHARED STYLES
 */
.l-container {
  @include clearfix;
  @include outer-container;
  position: relative;
  max-width: 1200px;
  margin: 0 auto;

  @include respond-to(large) {
    border-right: 1px solid $gray1;
    border-left: 1px solid $gray1;
  }
}
