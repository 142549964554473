.wpcf7-text {
  padding: 10px 16px;
  outline: 0;
  border: 1px solid $gray2;
}

.wpcf7-submit {
  @extend .btn;
  @extend .btn--large;
}

.wpcf7-textarea {
  border: 1px solid $gray2;
}

input[type="text"],
input[type="email"],
input[type="url"] {
  padding: 10px 16px;
  outline: 0;
  border: 1px solid $gray2;
}
