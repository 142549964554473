.teaser--recipe {
  display: block;
  font-size: rem(18px);
  border: 1px solid $gray1;
  padding: 4px 4px 16px 4px;
  margin-bottom: 32px;

  @include respond-to(small) {
    margin-right: 2%;
    width: 32%;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  &:hover {
    .icon--recipe {
      opacity: 1;
      padding-top: 40%;
      background-position: center 40%;
    }
  }

  img {
    width: 100%;
    height: auto;
  }

  h3 {
    @include transition(color 0.2s);
    margin: 8px 0;
    padding: 0 16px;
    line-height: 1.2;

    &:hover{
      @extend %color_brand-red;
    }
  }

  p {
    margin-bottom: 0;
    line-height: 1.2;
    padding: 0 16px;
  }

  .teaser--recipe-img {
    position: relative;
    overflow: hidden;
  }

  .icon--recipe {
    @extend %color_white;
    @include transition(opacity 0.2s, padding 0.2s, background-position 0.2s);
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
    font-family: $base-headline-font;
    font-weight: normal;
    font-size: em(24px);
    line-height: 1;
    letter-spacing: 1px;
    padding-top: 60%;
    background-color: rgba($brand-blue, .8);
    background-position: center 20%;
    border: 4px double $white;

    @include respond-to(medium) {
      font-size: em(32px);
    }
  }
}
