.recipe-toolbar {
  @include clearfix;
  width: 100%;
  padding: 20px 30px;
  margin: 0 auto;
  text-align: center;
  .btn {
    @extend %display-inline-block;
    margin-left: 5px;
    margin-right: 5px;
    &:first-child{
      margin-left: 0;
    }
    &:last-child{
      margin-right: 0;
    }
  }
}

.icon--facebook-f {
  background-position: 10px center;
}

.icon--twitter-white {
  background-position: 10px center;
}

.icon--pinit {
  background-position: center;
}

.icon--printer {
  background-position: 10px center;
}
