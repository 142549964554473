/**
 * $MAIN NAVIGATION
 */
.nav__main {
  @extend %pull-right;
  margin-right: 30px;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    display: none;
    margin-right: 0;
    margin-top: 16px;
    padding: 16px 0;
    text-align: center;
    float: none;
    background-color: $white;
    border-top: 1px solid $brand-blue;
    border-bottom: 1px solid $brand-blue;
    width: 100%;

    &.show-mobile {
      display: block;
      position: absolute;
      z-index: 10;

    }

    &:after, &:before {
      bottom: 100%;
      right: 60px;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #ffffff;
      border-width: 10px;
      margin-right: -10px;
    }
    &:before {
      border-color: rgba(111, 204, 216, 0);
      border-bottom-color: $brand-blue;
      border-width: 11px;
      margin-right: -11px;
    }
  }

  #footer &{
    float: none;
  }
  ul {
    @include horizontal-list;
    margin-top: 20px;

    @media (max-width: 600px) {
      margin: 10px 0 12px 0;
      > li {
        float: none;
        display: inline-block;
      }
    }

    > li { margin-right: 2px; }
  }

  a {
    @extend %color_brand-gray;
    @include transition(color 0.2s);
    padding: 2px 6px;
    border-bottom: 3px solid;
    text-decoration: none;
    font-size: em(24px);
  }

  li:first-child a {
    border-color: $brand-red;
    &:hover { @extend %color_brand-red; }
  }
  li:nth-child(4n+2) a {
    border-color:$brand-green;
    &:hover { @extend %color_brand-green; }
  }
  li:nth-child(4n+3) a {
    border-color: $brand-blue;
    &:hover { @extend %color_brand-blue; }
  }
  li:nth-child(4n+4) a {
    border-color: $brand-gray;
    &:hover { @extend %color_brand-gray; }
  }
}

.footer__nav-alt{
  ul{
    @include horizontal-list;
  }
  li {
    margin-right: 20px;
    @include respond-to(mobile){
      margin-right: 10px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  a {
    @extend %color_brand-gray;
    @include transition(border 0.2s);
    text-decoration: none;

    &:hover {
      border-bottom: 1px solid $brand-gray;
    }
  }
}

.nav--filters {
  @include clearfix;

  h4 {
    margin: 0 0 16px 0;

    @include respond-to(small) {
      float: left;
      margin-top: 2px;
    }
  }

  a {
    display: block;
    padding: 14px;
    border-radius: 3px;
    background-color: rgba($brand-blue, 0.2);
    line-height: 1;
    font-size: rem(16px);
    text-align: center;
    transition: background-color 0.15s, color 0.15s;

    // @include respond-to(mobile) {
    //   text-
    // }
    @include respond-to(small) {
      padding: 10px 24px;
    }

    &:hover {
      background-color: tint($brand-blue, 90%);
      color: white;
    }
  }
}

.nav--filters-list {
  @include horizontal-list;
  float: left;

  @include respond-to(small) { margin-left: 16px; }
}

.nav--filters-list__item {
  @media (max-width: 639px) {
    width: 49%;

    &:nth-child(odd) { margin-right: 2%; }

    &:first-child { margin-bottom: 10px; }
  }

  @media (min-width: 640px) {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

// #filters h2 a {
//   @extend %color_brand-green;

//   &:hover,
//   &:active,
//   a:focus{
//     border-bottom:2px solid $brand-green;
//     text-decoration: none;
//   }
// }

// .ingredients {
//   @extend %background_white;
//   position: relative;
//   display: none;
//   border-bottom: 1px solid $gray1;

//   form {
//     @include clearfix;
//     padding: 50px 0 30px 0;
//     background-color: rgba($brand-green, 0.10);
//   }

//   ul {
//     list-style: none;
//     margin: 0;
//     padding: 0;

//     @media (max-width: 599px) {
//       -webkit-column-count: 2;
//       -moz-column-count: 2;
//       -o-column-count: 2;
//       column-count: 2;
//       -webkit-column-gap: 20px;
//       -moz-column-gap: 20px;
//       -o-column-gap: 20px;
//       column-gap: 20px;
//       columns: 2;
//     }
//     @media (min-width: 600px) {
//       -webkit-column-count: 3;
//       -moz-column-count: 2;
//       -o-column-count: 2;
//       column-count: 2;
//       -webkit-column-gap: 20px;
//       -moz-column-gap: 20px;
//       -o-column-gap: 20px;
//       column-gap: 20px;
//       columns: 2;
//     }
//     @include respond-to(small) {
//       -webkit-column-count: 5;
//       -moz-column-count: 5;
//       -o-column-count: 5;
//       column-count: 5;
//       -webkit-column-gap: 20px;
//       -moz-column-gap: 20px;
//       -o-column-gap: 20px;
//       column-gap: 20px;
//       columns: 5;
//     }
//     @include respond-to(medium) {
//       -webkit-column-count: 7;
//       -moz-column-count: 7;
//       -o-column-count: 7;
//       column-count: 7;
//       -webkit-column-gap: 10px;
//       -moz-column-gap: 10px;
//       -o-column-gap: 10px;
//       column-gap: 10px;
//       columns: 7;
//     }
//   }

//   input { @include element-hidden; }

//   p {
//     margin: 0 0 10px;
//     text-align: center;
//     line-height: normal;
//   }

//   a {
//     @extend %color_brand-green;
//     @extend %display-inline-block;
//     @include transition(background-color 0.2s, border 0.2s);
//     text-decoration: none;
//     padding: 4px 10px;
//     border: 1px solid transparent; // NOTE: Hidden color to avoid offset when actual border shows

//     &:hover {
//       background-color: rgba($brand-green, 0.1);
//       border: 1px solid $brand-green;
//       text-decoration: none;
//     }
//     &.checked {
//       background-color: rgba($brand-green, 0.1);
//       border: 1px solid rgba($brand-green, 0.4);
//       color: rgba($brand-green, 0.7);
//     }
//   }

//   .btn {
//     margin: 10px 30px 0 0;
//   }

//   .btn--close {
//     position: absolute;
//     right: 16px;
//     top: 16px;
//     margin: 0;
//   }
//   button {
//     float: right;
//     @include respond-to(small) {

//     }
//   }
// }


