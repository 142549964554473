.recipe-notes{
  @extend %color_gray2;
  margin-right: 100px;
  font-size: rem(16px);
  h3{
    font-size: em(32px)
  }
  ul{
    padding: 0;
    list-style: none;
    font-size: em(18px);
    line-height: 1.2;
  }
  li{
    margin-bottom: em(16px);
    padding-left: 40px;
    background-position: 10px top;
  }
}
