.l-sidebar {
  padding: 8px 30px 0 30px;
  font-size: rem(16px);

  @include respond-to(medium) {
    @include span-columns(4);
    padding-left: 0;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    line-height: normal;
    margin: 0 0 8px;
  }

  a {
    @extend %color_brand-gray;
    text-decoration: none;

    a:hover,
    a:focus,
    a:active { text-decoration: underline; }
  }
}

.recipe--list {
  margin-bottom: 30px;

  h3 {
    border-bottom: 2px solid $brand-green;
    padding-bottom: 8px;
  }

  ul {
    @media (max-width: 479px){
      -webkit-column-count: 1;
      -moz-column-count: 1;
      -o-column-count: 1;
      column-count: 1;
      -webkit-column-gap: 0;
      -moz-column-gap: 0;
      -o-column-gap: 0;
      column-gap: 0;
      columns: 1;
    }

    @media (min-width: 480px){
      -webkit-column-count: 2;
      -moz-column-count: 2;
      -o-column-count: 2;
      column-count: 2;
      -webkit-column-gap: 20px;
      -moz-column-gap: 20px;
      -o-column-gap: 20px;
      column-gap: 20px;
      columns: 2;
    }

    @include respond-to(medium){
      -webkit-column-count: 1;
      -moz-column-count: 1;
      -o-column-count: 1;
      column-count: 1;
      -webkit-column-gap: 0;
      -moz-column-gap: 0;
      -o-column-gap: 0;
      column-gap: 0;
      columns: 1;
    }
  }

  li { @include clearfix; }

  h4 {
    font-size: em(21px);
    line-height: 1.1;
    margin: 0 0 4px;
  }

  p { margin: 0; }
}

.related-recipe--thumbnail {
  display: block;
  float: left;
  width: 76px;
  height: auto;
  margin: 0 10px 10px 0;
  border-radius: 50%;
  border: 2px solid $brand-green;
  overflow: hidden;
}

.sidebar__social {
  @include horizontal-list;
  li {
    margin: 0 5px;
  }
}
