.jamie-sidebar{
	padding-top: 30px;
	padding-bottom: 15px;
}
.jamie-sidebar .lead{
	font-weight: bold;
}
.jamie-sidebar-photo{
	float: right;
	margin-bottom: 10px;
	margin-left: 10px;
	max-width: 180px;
	width: 40%;
	border-radius: 50%;
}
.jamie-sidebar a{
	font-weight: bold;
}