.tags {
  padding: 10px 30px 30px 30px;
  @include respond-to(medium){
    padding-right: 0;
  }
  strong { @extend %color_brand-gray; }
  a {
    @extend %color_gray2;
    text-decoration: none;
    font-size: em(14);
  }
  p {
    @extend %color_gray2;
    border: 1px solid $gray1;
    padding: 30px;
    margin: 0;
  }
}
