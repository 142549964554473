.pagination{
	margin-bottom:75px;
}
.prev-link{
	float:left;
	width:50%;
	padding-left:60px;
}
.next-link{
	float:right;
	width:50%;
	text-align:right;
	padding-right:80px;
}

.search-result{
	// margin-bottom: 6px;
 //  margin: 0;
	// @media (min-width: 640px) {
	// 	display: block;
	// 	vertical-align:top;
	// 	// min-height:500px;
	// 	float: none;
	// 	max-width: 30%;
 //    margin: 15px 30px 15px 0;

 //    &:nth-child(3n) {
 //      margin-right: 0;
 //    }
	// }
}
