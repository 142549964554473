.recipe-details{
  position: relative;
  margin-bottom: 20px;
  padding: 16px;
  border: 4px double rgba($brand-green, 0.4);

  @include respond-to(small) {
    float: right;
    margin: 0 0 20px 20px;
    max-width: 320px;
  }

  @include respond-to(medium) { margin-right: 30px; }

  ul {
    list-style: none;
    padding: 24px;
    margin: 0 0 16px 0;
    background-color: rgba($brand-green, 0.16);
    line-height: 1;
    font-size: rem(16px);
  }

  li {
    background-position: left center;
    padding: 3px 0 3px 32px;
    margin-bottom: 16px;

    &:last-child { margin-bottom: 0; }
  }
}
