/**
 * $BASE-TYPOGRAPHY
 */
html{
  @extend %color_brand-gray;
  font-size: 84%;
  line-height: $base-line-height;
  font-family: $base-font-family;

  @include respond-to(mobile){
    font-size: 80%;
  }
  @include respond-to(small){
    font-size: 90%;
  }
  @include respond-to(medium){
    font-size: 100%;
  }
}



#main{
  font-size: rem(21px);
}
/**
 * $LINKS
 */
 a{
  @extend %color_brand-gray;
  text-decoration: none;
 }

 /**
 * $HEADINGS
 */
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $base-headline-font;
  font-weight: normal;
  line-height: 1.2;
  margin: 0 0 em(12px);
}


h1, .h1{ font-size: em(48px); }
h2, .h2{ font-size: em(38px); }
h3, .h3{ font-size: em(28px); }
h4, .h4{ font-size: em(18px); }
h5, .h5{ font-size: em(16px); }



/**
 * $PARAGRAPHS
 */
p, %p {
  font-size: em(16);
  margin: 0 0 em(18px);
}

/**
 * $LINKS
 */
p a {
  @extend %color_brand-blue;
  @include transition(border 0.2s);
  text-decoration: none;

  &:hover{
    border-bottom: 2px solid $brand-blue;
  }
}

article header{
  font-size: rem(16);
}
