// Colors
%color_brand-gray{
  color: $brand-gray;
}
%color_brand-red{
  color: $brand-red;
}
%color_brand-green{
  color: $brand-green;
}
%color_brand-blue{
  color: $brand-blue;
}
%color_gray2{
  color: $gray2;
}
%color_white{
  color: $white;
}


// Backgrounds
%background_brand-gray{
  background-color: $brand-gray;
}
%background_brand-red{
  background-color: $brand-red;
}
%background_brand-green{
  background-color: $brand-green;
}
%background_brand-blue{
  background-color: $brand-blue;
}
%background_white{
  background-color: $white;
}

// Layouts
%pull-right {
  float: right;
}
%pull-left {
  float: left;
}
%display-block {
  display: block;
}
%display-inline-block {
  display: inline-block;
}
