.recipe-info {
  @include clearfix;
  background-color: rgba($gray1, 0.15);
  border: 1px solid rgba($brand-red, 0.20);
  padding: 24px;
  margin: 0 30px 30px 30px;
  font-size: rem(16px);

  @include respond-to(medium) { margin-right: 0; }

  h3 {
    @extend %color_brand-red;
    font-size: em(32px)
  }

.ing-section-header{
    margin-top: 15px;
    margin-bottom: 15px;
  }

  ul, ol {
    margin: 0;
    padding: 0;

    li {
      font-size: em(18px);
      line-height: 1;
      margin-bottom: em(16px);
    }
  }

  ol { list-style-position: inside; }

  ul {
    list-style: none;

    @include respond-to(small) {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      -o-column-count: 2;
      column-count: 2;
      -webkit-column-gap: 30px;
      -moz-column-gap: 30px;
      -o-column-gap: 30px;
      column-gap: 30px;
      columns: 2;
    }
  }

  input{
    @extend %display-inline-block;
    margin-right: 10px;
  }
}
