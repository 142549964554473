.newsletter-signup {
  font-size: rem(18px);

  form { @include clearfix; }

  label {
    display: block;
    font-size: em(18px);
  }
  input[type="email"] {
    padding: 10px 16px;
    outline: 0;
    width: 100%;
    margin-bottom: 16px;
  }
  input[type="submit"] {
    float: right;
    outline: 0;
  }
}

#mce-success-response,
#mce-error-response {
  margin-top: 16px;
  padding: 16px;
  font-size: em(15px);
  line-height: 1.2;
}
#mce-error-response {
  background-color: rgba($brand-red, 0.1);
  border: 1px solid $brand-red;
  color: $brand-red;
}
#mce-success-response {
  background-color: rgba($brand-green, 0.1);
  border: 1px solid $brand-green;
  color: $brand-green;
}
