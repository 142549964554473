.recipe-index {
  padding: 30px;
}
.archived-recipes {
  @include clearfix;
  padding: 30px 0;
}

.archived-recipes__title {
  padding: 16px;
  margin: 0;
  background-color: rgba($brand-blue, 0.08);

  .archived-beverages & {
    background-color: rgba($brand-red, 0.08);
  }
  .archived-breakfasts & {
    background-color: rgba($brand-green, 0.08);
  }
  .archived-desserts & {
    background-color: rgba($brand-gray, 0.08);
  }
  .archived-lunches & {
    background-color: rgba($brand-blue, 0.08);
  }
  .archived-main-dishes & {
    background-color: rgba($brand-red, 0.08);
  }
  .archived-pasta & {
    background-color: rgba($brand-green, 0.08);
  }
  .archived-preserving & {
    background-color: rgba($brand-gray, 0.08);
  }
  .archived-salads & {
    background-color: rgba($brand-blue, 0.08);
  }
  .archived-sandwiches & {
    background-color: rgba($brand-red, 0.08);
  }
  .archived-sides & {
    background-color: rgba($brand-green, 0.08);
  }
  .archived-slow-cooker & {
    background-color: rgba($brand-gray, 0.08);
  }
  .archived-snacks & {
    background-color: rgba($brand-blue, 0.08);
  }
  .archived-soup & {
    background-color: rgba($brand-red, 0.08);
  }
  .archived-ingredients & {
    background-color: rgba($brand-green, 0.08);
  }
}

.index-list {
  padding: 30px 10px;
  margin: 0;
  font-size: rem(18px);
  line-height: 1.2;
  border-top: 1px solid $brand-blue;
  list-style-type: none;

  @media (min-width: 640px) { column-count: 2; }
  @include respond-to(medium) { column-count: 3; }

  li { margin-bottom: 16px; }

  a {
    border-bottom: 1px solid rgba($brand-blue, 0.6);
    transition: border 0.15s;

    &:hover {
      border-color: rgba($brand-blue, 1);
    }
  }

  .archived-beverages & {
    border-color: $brand-red;

    a {
      border-color: rgba($brand-red, 0.6);

      &:hover { border-color: rgba($brand-red, 1); }
    }
  }
  .archived-breakfasts & {
    border-color: $brand-green;

    a {
      border-color: rgba($brand-green, 0.6);

      &:hover { border-color: rgba($brand-green, 1); }
    }
  }
  .archived-desserts & {
    border-color: $brand-gray;

    a {
      border-color: rgba($brand-gray, 0.6);

      &:hover { border-color: rgba($brand-gray, 1); }
    }
  }
  .archived-lunches & {
    border-color: $brand-blue;

    a {
      border-color: rgba($brand-blue, 0.6);

      &:hover { border-color: rgba($brand-blue, 1); }
    }
  }
  .archived-main-dishes & {
    border-color: $brand-red;

    a {
      border-color: rgba($brand-red, 0.6);

      &:hover { border-color: rgba($brand-red, 1); }
    }
  }
  .archived-pasta & {
    border-color: $brand-green;

    a {
      border-color: rgba($brand-green, 0.6);

      &:hover { border-color: rgba($brand-green, 1); }
    }
  }
  .archived-preserving & {
    border-color: $brand-gray;

    a {
      border-color: rgba($brand-gray, 0.6);

      &:hover { border-color: rgba($brand-gray, 1); }
    }
  }
  .archived-salads & {
    border-color: $brand-blue;

    a {
      border-color: rgba($brand-blue, 0.6);

      &:hover { border-color: rgba($brand-blue, 1); }
    }
  }
  .archived-sandwiches & {
    border-color: $brand-red;

    a {
      border-color: rgba($brand-red, 0.6);

      &:hover { border-color: rgba($brand-red, 1); }
    }
  }
  .archived-sides & {
    border-color: $brand-green;

    a {
      border-color: rgba($brand-green, 0.6);

      &:hover { border-color: rgba($brand-green, 1); }
    }
  }
  .archived-slow-cooker & {
    border-color: $brand-gray;

    a {
      border-color: rgba($brand-gray, 0.6);

      &:hover { border-color: rgba($brand-gray, 1); }
    }
  }
  .archived-snacks & {
    border-color: $brand-blue;

    a {
      border-color: rgba($brand-blue, 0.6);

      &:hover { border-color: rgba($brand-blue, 1); }
    }
  }
  .archived-soup & {
    border-color: $brand-red;

    a {
      border-color: rgba($brand-red, 0.6);

      &:hover { border-color: rgba($brand-red, 1); }
    }
  }
  .archived-ingredients & {
    border-color: $brand-green;

    a {
      border-color: rgba($brand-green, 0.6);

      &:hover { border-color: rgba($brand-green, 1); }
    }
  }
}
