// -----------------------------------------------------------------------------
// Base button
// -----------------------------------------------------------------------------
.btn{
  @extend %background_brand-green;
  @extend %color_white;
  @include transition(background-color 0.2s, border 0.2s);
  border: 1px solid darken($brand-green, 10%);
  padding: 6px 16px;
  text-decoration: none;
  font-size: rem(16px);
  letter-spacing: 1px;
  line-height: 1;
  cursor: pointer;
  overflow: hidden;
  box-shadow: inset 0 0 0 1px rgba($white, 0.3);

  &:hover {
    box-shadow: none;
    border: 1px solid $brand-green;
    background-color: darken($brand-green, 10%);
  }
}

.btn--large {
  padding: 14px 21px;
  font-size: rem(18px);
}

a.btn--red {
  background-color: $brand-red;
  border: 1px solid darken($brand-red, 10%);
  margin-top: 15px;
  display: inline-block;
  text-align: center;
  color: #fff;

  &:hover {
    border-color: $brand-red;
    background-color: darken($brand-red, 10%);
  }
}

.btn--facebook {
  padding: 10px 16px 8px 32px;
  border: 1px solid darken($facebook, 20%);
  background-color: $facebook;
  line-height: em(24px);
  box-shadow: inset 0 0 0 1px rgba($white, 0.3);

  &:hover{
    box-shadow: none;
    border: 1px solid $facebook;
    background-color: darken($facebook, 10%);
  }
}

.btn--twitter {
  padding: 10px 16px 8px 42px;
  border: 1px solid darken($twitter, 20%);
  background-color: $twitter;
  line-height: em(24px);
  box-shadow: inset 0 0 0 1px rgba($white, 0.3);

  &:hover{
    box-shadow: none;
    border: 1px solid $twitter;
    background-color: darken($twitter, 10%);
  }
}

.btn--pinterest {
  @include text-invisible;
  padding: 10px 16px 8px 42px;
  border: 1px solid darken($pinterest, 20%);
  background-color: $pinterest;
  line-height: em(24px);
  box-shadow: inset 0 0 0 1px rgba($white, 0.3);

  &:hover{
    box-shadow: none;
    border: 1px solid $pinterest;
    background-color: darken($pinterest, 10%);
  }
}

.btn--print {
  padding: 10px 16px 8px 48px;
  line-height: em(24px);
}

.btn--icon-only{
  @include text-invisible;
  background-color: transparent;
  border: none;
  box-shadow: none;
  background-position: center;

  &:hover{
    background-color: transparent;
    border: 0;
  }
}

.btn--close {
  @include text-invisible;
  width: 21px;
  height: 21px;
  padding: 0;
  background-color: rgba($brand-green, 0.20);
  background-position: center center;
  border: 1px solid transparent;
  border-radius: 50%;

  &:hover{
    border: none;
    background-color: darken($brand-green, 10%);
  }
}

.btn--jump {
  @extend %display-block;
  text-align: center;
  padding: 8px;
  width: 100%;
}

.btn--resize {
  @include text-invisible;
  position: absolute;
  display: none;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: rgba($black, 0.3);
  background-position: center center;

  @include respond-to(small) { display: block; }
  @include respond-to(medium) { display: none; }

  &:hover {
    border: none;
  }
}

.btn--close-blue {
  @extend .btn--close;
  background-color: rgba($brand-blue, 0.20);

  &:hover {
    background-color: darken($brand-blue, 10%);
  }
}

.btn--mobile-nav {
  position: absolute;
  top: 30px;
  right: 24px;
  border: none;
  background-color: $brand-blue;
  font-size: rem(21px);

  @media (min-width: 601px) { display: none; }

  &:hover, &:active {
    background-color: darken($brand-blue, 10%);
    border: none;
  }
}

.view-recipe {
  @include transition(color 0.2s);
  @extend %color_brand-blue;

  &:hover {
    border: none;
    color: darken($brand-blue, 20%);
  }
}

.btn--search {
  width: 34%;
  text-transform: uppercase;
  letter-spacing: em(2px);

  @include respond-to(small) {
    margin-top: 0;
    width: 20%;
  }

  @include respond-to(medium) { width: 18%; }
}

.btn--back-to-top {
  display: block;
  position: fixed;
  bottom: 30px;
  right: 5%;
  z-index: 2000;
  width: 64px;
  height: 64px;
  background-color: rgba($gray1, 0.25);
  background-position: center center;
  border-radius: 6px;
  border: 1px solid $gray1;
  cursor: pointer;
  text-indent: em(-9999px);
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s 0s, visibility 0s .3s;

  @include respond-to(mobile) {
    background-size: 60%;
    width: 48px;
    height: 48px;
  }

  &.btn--isvisible {
    visibility: visible;
    opacity: 1;
  }
}


