.search-form {
  @include clearfix;
  margin-bottom: 20px;
  width: 100%;
}

.search-field {
  float: left;
	width: 78%;
	padding: 11px 20px 10px 20px;
  margin-right: 2%;
  font-family: $base-headline-font;
  letter-spacing: em(2px);
  outline: 0;
  border: 1px solid $gray1;

  @include respond-to(mobile) { width: 64%; }

  @include respond-to(medium) { width: 80%; }

  @include placeholder { color: $gray1; }
}

.recipe-index-filter {
  padding: 40px 30px;
  background-color: rgba($brand-blue, 0.05);
  border-bottom: 1px solid $gray1;
}
