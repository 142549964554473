.article--header {
  @include clearfix;

  header {
    margin-left: 30px;
    margin-right: 30px;

    @include respond-to(medium) { margin-right: 0; }
  }
}
.article--title {
  @extend %color_brand-red;
  margin-bottom: rem(16px);
  line-height: 1.2;
}

.recipe-single {
  padding-top: 8px;

  @include respond-to(medium) { @include span-columns(8); }
}

.l-article {
  .content {
    margin-bottom: 60px;

    @include respond-to(medium) {
      //border-right: 1px solid $brand-blue;

      p {
        padding-left: 20px;
        padding-right: 50px;
      }
    }
  }
}

.l-article-wrap {
  padding-left: 30px;
  padding-right: 30px;

  @include respond-to(medium) {
    padding-right: 10px;
    border-right: 1px solid $brand-blue;
  }
}
