#main__header {
  @include clearfix;
  border-bottom: 1px solid $gray1;

  .l-container{
    padding-top: 30px;
    // padding-bottom: 30px; NOTE: unsure how to resolve this. comeback to

    @media (max-width: 600px) { padding-top: 24px; }
    @include respond-to(mobile) { background-position: -120px -80px; }
    @include respond-to(small) { background-position: -50px -70px; }
    @include respond-to(medium){ background-position: 0 -30px; }

    .single &{
      padding-bottom: 0;
    }
  }
}

.l-brand {
  @extend %pull-left;
  padding-left: 30px;

  @media (max-width: 600px) {
    padding-left: 0;
    float: none;
  }
  h1 {
    margin: 0;
    // @media (max-width: 600px) {
    //   margin: 0 auto;
    // }
    @media (max-width: 600px) {
      width: 160px; //240px; //195px;
      height: 35px; //52px; //41px;
      margin: 0 0 0 24px;
    }
    @media (min-width: 601px) {
      width: 240px; //195px;
      height: 52px; //41px;
    }
    @include respond-to(small) {
      width:240px;
      height:52;
    }
    @include respond-to(medium) {
      width:310px;
      height:69px;
    }
  }

  span { @include element-invisible; }
}

.l-main-nav {
  @include clearfix;
}

.callout--about {
  text-align: right;
  padding-bottom: 30px;
  padding-right: 30px;
  margin-bottom: 0;
  font-size: em(18px);

  @media (max-width: 600px) {
    text-align: center;
    font-size: em(21px);
    padding: 16px 24px;
    line-height: 1.1;
  }
}
