.recipe-list{
  border-bottom: 1px solid $gray1;
  padding: 60px 0;

  @include respond-to(large){
    padding-left: 30px;
    padding-right: 30px;
  }

  &:last-child{ border-bottom: none; }
  &:nth-child(4n+1) h2 { border-color:#e2725f; }
  &:nth-child(4n+2) h2 { border-color:#bcbc54; }
  &:nth-child(4n+3) h2 { border-color:#6fccd8; }

  ul{
    @include outer-container;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li{
    @include respond-to(medium){
      @include span-columns(4);
      @include omega(3n);
    }
  }
  h2 { border-bottom:3px solid; }

  img{
    width: 80px;
    height: 80px;
  }

  a {
    @extend %display-inline-block;
    width:100%;
    text-decoration:none;
    position:relative;
    padding:0;
    display:inline-table;
  }
}
